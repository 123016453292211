import { DatadogLoggingService } from '@edx/frontend-logging';

class CustomDatadogLoggingService extends DatadogLoggingService {
  beforeSend(event, context) {
    if (
      event.type === "error" &&
      event.error.message.includes(
        "Failed to find and set button ID in TinyMceLanguageSelectorPlugin after 3 attempts"
      )
    ) {
      return false;
    }
    return super.beforeSend(event, context);
  }
}

const config = {
  loggingService: CustomDatadogLoggingService,
};

export default config;
